import { TextBlock } from '../../components/TextBlock'

export const Home = () => {
  return (
    <>
      <TextBlock>
        <div className="pb-4 md:pb-0">
          <img src="/img/nadejnosti.png" alt="Reliability" />
        </div>
        <div className="md:w-1/3 md:pl-16">
          <h3 className="text-black text-2xl md:text-4xl pb-3">Reliability</h3>
          <p className="text-black text-base md:text-lg leading-7">
            The browser handles multiple tabs and processes without slowing down
            or performance issues.
          </p>
        </div>
      </TextBlock>
      <TextBlock>
        <div className="md:w-1/3 md:pr-16 order-2 md:order-1">
          <h3 className="text-black text-2xl md:text-4xl pb-3">
            Privacy Protection
          </h3>
          <p className="text-black text-base md:text-lg leading-7">
            It conceals your personal information, including IP address,
            location, and identification data, to prevent tracking of your
            online activities.
          </p>
        </div>
        <div className="pb-4 md:pb-0 order-1 md:order-2">
          <img src="/img/privacy.png" alt="Privacy" />
        </div>
      </TextBlock>
      <TextBlock>
        <div className="pb-4 md:pb-0">
          <img src="/img/access.png" alt="Access" />
        </div>
        <div className="md:w-1/3 md:pl-16">
          <h3 className="text-black text-2xl md:text-4xl pb-3">
            Access to Blocked Websites
          </h3>
          <p className="text-black text-base md:text-lg leading-7">
            The browser bypasses geographical restrictions and website blocks,
            allowing you to access content that is not available in your region.
          </p>
        </div>
      </TextBlock>
    </>
  )
}
