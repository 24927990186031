import { PropsWithChildren } from 'react'
import cn from 'classnames'

interface IButtonProps extends PropsWithChildren {
  title?: string
  text: string
  iconAlign?: 'left' | 'right'
}

export const Button = (props: IButtonProps) => {
  const { title, text, iconAlign = 'left', children } = props

  return (
    <button className="inline-flex items-center rounded-[20px] md:rounded-[34px] px-4 md:px-5 py-2 md:py-4 bg-red text-white">
      {iconAlign === 'left' ? <span className="pr-2">{children}</span> : null}
      <span className="inline-flex flex-col items-start">
        <span className="text-xs leading-1">{title}</span>
        <span
          className={cn({
            ['text-lg font-semibold leading-none']: Boolean(title),
          })}
        >
          {text}
        </span>
      </span>
      {iconAlign === 'right' ? <span className="pl-2">{children}</span> : null}
    </button>
  )
}
