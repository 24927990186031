export const Privacy = () => {
  return (
    <div className="w-full lg:w-3/4 mx-auto">
      <h2 className="text-2xl font-bold mb-4">Privacy Notice</h2>
      <div className="mb-4 italic">Last Updated: 10 October 2023</div>
      <p className="mb-4">
        This Privacy Notice is developed by AP Service LLC, the company
        established under the laws of Georgia, having its company registration
        number: 405445111, and registered address at 40 Ilia Chavchavadze
        Avenue, Vake District, Tbilisi, Georgia.
      </p>
      <h3 className="text-xl font-bold mb-4">Introduction</h3>
      <p className="mb-4">
        In accordance with applicable laws, we are obligated to adhere to
        personal data protection and processing procedures. Our commitment is to
        maintain the secure storage and processing of your data while respecting
        and safeguarding your rights as a data subject. We take these
        responsibilities seriously and have put in place appropriate technical
        and organisational measures to ensure the privacy and security of your
        data. Any processing of your personal data as described in this Privacy
        Notice will be conducted in compliance with Regulation (EU) 2016/679 of
        The European Parliament And of The Council of 27 April 2016 On The
        Protection of Natural Persons With Regard to The Processing of Personal
        Data And On The Free Movement of Such Data, And Repealing Directive
        95/46/EC.
      </p>
      <p className="mb-4">
        This Privacy Notice is an addition to Our Terms and Conditions and
        should be read in conjunction with the Terms and Conditions. By using
        this website, you agree to be bound by the terms of this Privacy Notice.
      </p>
      <p className="mb-4">
        We review our Privacy Notice from time to time and reserve the right, at
        our discretion, to amend this Notice at any time without any notice
        other than posting the amended Privacy Notice on Our Website. Amendments
        to our Privacy Notice will take effect immediately once posted on our
        Website. Your continued use of our services following the posting of an
        amended Privacy Notice will be deemed as acceptance of those amendments.
      </p>
      <p className="mb-4">
        It is your responsibility to ensure that you keep up-to-date with the
        current Privacy Notice.
      </p>
      <p className="mb-4">
        You should review this page periodically so that you are updated on any
        changes to the Notice.
      </p>
      <h3 className="text-xl font-bold mb-4">Definitions</h3>
      <p className="mb-4">
        Data controller: this refers to the individual or entity, whether a
        natural person or a legal entity, a public authority, an agency, or
        another body, who, either on their own or in collaboration with others,
        determines the purposes and methods of processing personal data. When
        the objectives and methods of this processing are established by
        European Union or Member State law, the controller, or the specific
        criteria for their appointment, may be specified by European Union or
        Member State law.
      </p>
      <p className="mb-4">
        Data processor: data processor is a natural or legal person, public
        authority, agency, or other entity that carries out the processing of
        personal data on behalf of the data controller.
      </p>
      <p className="mb-4">
        Data subject: this refers to an identifiable natural person who can be
        distinguished through direct or indirect identifiers.
      </p>
      <p className="mb-4">
        GDPR: means EU Regulation 2016/679 – the General Data Protection
        Regulation;
      </p>
      <p className="mb-4">
        Personal data: is any information relating to an individual, called the
        data subject, that us to identify him. This information may be directly
        or indirectly associated with an individual, through identifiers such as
        physical, genetic, or social characteristics.
      </p>
      <p className="mb-4">
        Processing: encompasses any operation or series of operations performed
        on personal data or sets of personal data, whether by automated means or
        not. These operations include collection, recording, organization,
        structuring, storage, adaptation or alteration, retrieval, consultation,
        use, disclosure by transmission, dissemination or otherwise making
        available, alignment or combination, restriction, erasure, or
        destruction of personal data.
      </p>
      <p className="mb-4">
        AP Service LLC, Supplier We, Us, Website: the following terms
        collectively represent the party responsible for providing the Software.
        You, Client: means Instance administrator
      </p>
      <h3 className="text-xl font-bold mb-4">How we collect Personal Data</h3>
      <p className="mb-4">
        We will collect personal data only according to lawful and fair means.
      </p>
      <p className="mb-4">
        We collect your personal data directly from you unless it is
        unreasonable or impracticable to do so. We may collect this information
        when you:
      </p>
      <ul className="list-disc ml-8 mb-4">
        <li>access or use our website;</li>
        <li>register an account with us;</li>
        <li>use our products and services;</li>
        <li>
          participate in our competitions, giveaways and other promotions;
        </li>
        <li>
          contact us directly via any medium including SMS, MMS, instant
          messaging, email, social media platforms, postal mail and telephone;
        </li>
        <li>provide feedback through our website/app feedback links.</li>
      </ul>
      <h3 className="text-xl font-bold mb-4">
        Why do we collect, hold, use and disclose personal data
      </h3>
      <p className="mb-4">
        We collect and process your personal data based on the following legal
        grounds:
      </p>
      <p className="mb-4">
        Legal Requirement: We may process your personal data as mandated by
        European Union or Member State laws applicable to us.
      </p>
      <p className="mb-4">
        Contractual Necessity: Your personal data may be processed as necessary
        to fulfil obligations under a legally binding contract between you and
        us.
      </p>
      <p className="mb-4">
        Legitimate Interest: We may process your personal data when there is a
        legitimate business or commercial reason for doing so. Prior to relying
        on our legitimate interest, we assess it in relation to your interests
        and ensure that our interest is substantial and that pursuing it will
        not unreasonably infringe upon your rights and interests.
      </p>
      <p className="mb-4">
        We collect, hold, use and disclose personal information for purposes
        including the following:
      </p>
      <ul className="list-disc ml-8 mb-4">
        <li>to provide products and services to you;</li>
        <li>
          to enable us to perform our obligations to you and to ensure that you
          perform your obligations under the Terms and Conditions and the
          Agreement;
        </li>
        <li>
          for communicating with you, including sending you information about
          our products and services;
        </li>
        <li>
          for planning, research, promotion and marketing of our goods and
          services, including conducting competitions or promotions;
        </li>
        <li>
          to create aggregate data about Instance Administrators and it’s
          Partners through demographic profiling and statistical analysis of our
          database to optimise our products and services and /or allow for more
          efficient operation of our business;
        </li>
        <li>to prove your good faith;</li>
        <li>
          for the investigation of suspected unlawful, fraudulent or other
          improper activity connected with the use of our products and services.
        </li>
      </ul>
      <h3 className="text-xl font-bold mb-4">
        To whom Personal Data is disclosed
      </h3>
      <p className="mb-4">
        By using our Website and Software and providing personal data you
        consent to your personal data being used by us.
      </p>
      <p className="mb-4">
        We may disclose your personal data to third parties as follows:
      </p>
      <ul className="list-disc ml-8 mb-4">
        <li>
          to our contractors and external service providers associated with the
          operation of our business and provision of our services including,
          without limitation, associated data centres, web hosting providers,
          payment service providers, advertising agencies, mailing houses,
          printers, call centres, market research analysts, IT consultants,
          professional advisors and consultants;
        </li>
        <li>
          to law enforcement agencies to assist in the prevention of criminal
          activities;
        </li>
        <li>
          to government and regulatory authorities and other organisations as
          required or authorised by law or otherwise;
        </li>
        <li>
          controlling bodies where such controlling bodies request information
          to protect and maintain the integrity of services provided or where we
          consider any activity to be suspicious or unusual; and
        </li>
        <li>
          to a successor entity in the event of a business transition, such as a
          merger, corporate reorganisation or to a purchaser of part of or all
          of Our assets.
        </li>
      </ul>
      <p className="mb-4">
        Should the information be transferred to a third party, we will use
        reasonable measures to ensure that the information disclosed is
        protected by the third party under contractual arrangements.
      </p>
      <h3 className="text-xl font-bold mb-4">What are your rights?</h3>
      <p className="mb-4">
        The rights mentioned in this paragraph apply to all users visiting our
        website, and specifically to users from the European Union, United
        Kingdom and Switzerland.
      </p>

      <p className="mb-4">
        You have the following rights regarding the processing of your personal
        data, given to you through General Data Protection Regulation (GDPR):
      </p>
      <p className="mb-4">
        The right to be informed: You have a right to be informed about how your
        personal data is processed in a concise, transparent, intelligible and
        easily accessible manner, written in clear and plain language, that is
        free of charge. This is the purpose of this Privacy Notice.
      </p>
      <p className="mb-4">
        The right of access: You have the right to obtain confirmation that your
        data is being processed and to have access to that personal data.
      </p>
      <p className="mb-4">
        The right to rectification: You have the right to have your personal
        data rectified if it is incorrect or incomplete and we must notify any
        third parties that we have shared your data to do the same.
      </p>
      <p className="mb-4">
        The right to erasure: You have a “right to be forgotten”, or to have
        your personal data erased and to prevent processing. The right is not
        absolute and in certain circumstances, this may not be possible (for
        example where we have to keep your personal data for legal or regulatory
        reasons). We must notify any third parties that have shared your data of
        your request.
      </p>
      <p className="mb-4">
        The right to restrict processing: You have the right to ‘block’ or
        suppress the processing of your personal data. This does not stop the
        storing of your personal data, as it may be required to restrict
        processing in the future. We must notify any third parties that have
        shared your data of your request.
      </p>
      <p className="mb-4">
        The right to data portability: You have the right to obtain and reuse
        your personal data for your own purposes across different services in a
        safe and secure way, without hindrance to usability.
      </p>
      <p className="mb-4">
        The right to object: You have the right to object to processing based on
        legitimate interests or the performance of a task in the public
        interest/exercise of official authority (including profiling); direct
        marketing (including profiling); and processing for purposes of
        scientific/historical research and statistics.
      </p>
      <p className="mb-4">
        Rights in relation to automated decision making and profiling: You have
        a right to object to automated decision making, to express your point of
        view, ask for human intervention, and to be provided with an explanation
        of the decision with your option to challenge it.
      </p>
      <p className="mb-4">
        The right to withdraw consent: If you have given us explicit consent to
        process your personal data you have a right to withdraw that consent at
        any time, including but not limited to the removal of consent to be
        contacted for marketing purposes.
      </p>
      <p className="mb-4">
        The right to lodge a complaint with a supervisory authority: You have
        the right to lodge a complaint with us directly and / or with a
        supervisory authority. You can find their contact details here.
      </p>
      <h3 className="text-xl font-bold mb-4">Security of Personal Data</h3>
      <p className="mb-4">
        Your information is held on secured servers. We may also store personal
        data in telephone recordings and in hard copy or paper files.
      </p>
      <p className="mb-4">
        We will take reasonable steps to protect the personal data we collect
        and ensure that it is accurate, complete and up-to-date.
      </p>
      <p className="mb-4">
        Our employees, agents, contractors are required to maintain the
        confidentiality of all personal data.
      </p>
      <h3 className="text-xl font-bold mb-4">
        Access to and correcting Personal Data
      </h3>
      <p className="mb-4">
        We take reasonable measures to ensure that the personal data we collect
        about you is accurate, up-to-date and complete. We also take reasonable
        steps to ensure that the personal data we use or disclose is accurate,
        up-to-date, complete and relevant.
      </p>
      <p className="mb-4">
        If we are under the impression that your personal data should be
        corrected, we will take reasonable steps to correct it – this may
        include contacting you to ask you to provide your most current
        information.
      </p>
      <p className="mb-4">
        You may request access to and correction of the personal data we hold
        about you. We will require you to verify your identity and to specify
        what information you require. We will exert reasonable efforts to
        maintain the confidentiality of your Personal Data required for using
        the Services. We will promptly notify you upon discovering any potential
        or actual compromise of the security of your personal data.
      </p>
      <h3 className="text-xl font-bold mb-4">Personal Data retention</h3>
      <p className="mb-4">
        We understand the importance of security and techniques needed to secure
        information. We store all of the Personal data we receive directly from
        you in a protected database residing within our secure network, behind
        active state-of-the-art firewall software. Moreover, we do not store or
        process credentials of payment cards.
      </p>

      <p className="mb-4">
        The amount of time we hold your information depends on the reason it was
        provided. Your data will not be kept indefinitely and will only be kept
        for as long as is required for legitimate business reasons (for example
        to provide you with our Services or for the other reasons we explained
        above) and in order for us to comply with our legal and regulatory
        obligations.
      </p>
      <p className="mb-4">
        The personal data we receive when you make an application (whether on
        our website or which comes to us via one of the websites of our
        affiliates) is kept for a minimum of five years, which will commence at
        the end of the relationship. We are required to do so to ensure that if
        any disputes or complaints are raised, we have your information to
        enable us to address the complaints or disputes.
      </p>
      <p className="mb-4">
        If you request that we do not contact you for advertising and marketing
        reasons, we will need to keep your information as this would reflect
        your preferences.
      </p>
      <h3 className="text-xl font-bold mb-4">Data processing clause</h3>
      <p className="mb-4">
        While utilising our software, you will assume the role of a personal
        data controller, defining the limits on the collection of personal data
        for your Partners and Advertisers. In such cases, we would act as a data
        processor. The specific boundaries and purposes of this processing will
        be outlined in the respective Data Processing Addendum (DPA).
      </p>
      <p className="mb-4">
        The party that initially obtains personal data from a data subject bears
        the responsibility for obtaining any necessary consents from the data
        subject (to the extent required by Data Protection Laws) and for
        providing information to the data subject prior to collecting their
        personal data, as mandated by Data Protection Laws. This does not
        absolve the other party of its obligations under Data Protection Laws,
        such as the duty to provide information to the data subject in
        connection with the processing of personal data.
      </p>
      <p className="mb-4">
        You also have the option to appoint subcontractors, including
        Affiliates, to process personal data within our software
        ("Sub-Processors"). These appointed subcontractors must uphold a level
        of protection for personal data that is equivalent to the standards
        outlined in this Privacy Notice and Data Protection Addendum. It is your
        sole responsibility to ensure compliance with these requirements.
      </p>
      <p className="mb-4">
        You will be solely liable for the actions or omissions of any
        Sub-Processors appointed by you.
      </p>
      <p className="mb-4">
        Any party that has access to or processes Personal Data shall:
      </p>
      <ul className="list-disc ml-8 mb-4">
        <li>
          Process the Personal Data strictly in accordance with the requirements
          of Data Protection Laws applicable to Data Controllers. They should
          also maintain accurate written records of all Personal Data processing
          activities as necessitated by Data Protection Laws.
        </li>
        <li>
          Be responsible for providing data subjects with any information
          required under Data Protection Laws, as well as enabling data subjects
          to exercise their rights as stipulated by Data Protection Laws.
          Additionally, they should provide the other party with reasonable
          cooperation and assistance to fulfil these obligations.
        </li>
        <li>
          Implement and maintain commercially reasonable and appropriate
          physical, technical, and organisational security measures to safeguard
          Personal Data. This includes protection against accidental or unlawful
          destruction, loss, alteration, unauthorised disclosure, or access to
          personal data during transmission, storage, or any other form of
          processing, in compliance with Data Protection Laws.
        </li>
        <li>
          Adhere to any obligations concerning the notification of personal data
          breaches to the supervisory authority and the communication
          obligations to data subjects, as mandated by Data Protection Laws.
        </li>
        <li>
          Promptly notify the other party, without undue delay and within
          twenty-four (24) hours, upon becoming aware of a breach incident.
        </li>
      </ul>
      <h3 className="text-xl font-bold mb-4">Notices</h3>
      <p className="mb-4">
        All notices and requests concerning your personal data shall be sent to
        the following email address:{' '}
        <a href="mailto:info@chcbrowser.com">info@chcbrowser.com</a>.
      </p>
      <p className="mb-4">
        Once we receive your request, we will acknowledge its receipt within ten
        (10) business days. We will respond to your verifiable consumer request
        within thirty (30) days of receiving it. If additional time is required
        (up to 90 days), we will notify you in writing, providing the reason for
        the extension.
      </p>
      <p className="mb-4">
        We do not charge a fee for processing or responding to your verifiable
        consumer request, unless it is excessive, repetitive, or manifestly
        unfounded. If we determine that a fee is warranted, we will provide an
        explanation for the decision and estimate the cost before completing
        your request.
      </p>
    </div>
  )
}
