export const CookiesPolicy = () => {
  return (
    <div className="w-full lg:w-3/4 mx-auto">
      <h2 className="text-2xl font-bold mb-4">Cookie Policy</h2>
      <div className="mb-4 italic">Last Updated: 10 October 2023</div>
      <p className="mb-4">
        We, the team at AP Service LLC, located at 40 Ilia Chavchavadze Avenue,
        Vake District, Tbilisi, Georgia, are delighted to have you on board. Our
        registered number is 405445111. We're excited to provide you with our
        Services through our user-friendly software. This document is here to
        lay out our Cookie Policy. Feel free to explore and enjoy.
      </p>
      <p className="mb-4">
        Anonymous CHC Browser (hereinafter, “Browser”) uses cookies. By using
        the Browser you agree with our Cookie Policy (“Policy”) and with the
        Browser's use of cookies in accordance with the terms of this Policy.
      </p>
      <p className="mb-4">
        This Cookie Policy and AP Service Privacy Notice constitute integral
        parts of AP Service Terms of Service. Unless otherwise specified, the
        terms used in this document shall have the same meaning as in AP Service
        Terms of Service.
      </p>
      <h3 className="text-xl font-bold mb-4">What are Cookies?</h3>
      <p className="mb-4">
        Cookies are small text files that are downloaded on your device (e.g. a
        computer or smartphone) when you access the Browser. Cookies support the
        operation of our Browser as well as allow the Browser to recognize your
        device and store some information about your preferences or past
        actions. Below we explain the cookies we use and why.
      </p>
      <h3 className="text-xl font-bold mb-4">Why do we use Cookies?</h3>
      <p className="mb-4">
        Cookies aim to make a Browser more efficient for users. They allow
        personalized settings and web pages to be offered to provide a more
        convenient and faster visit experience tailored to your needs. These
        files store information about your Browser navigation. When you use the
        Browser again, your devices will remember your data thanks to the
        information kept in the file.
      </p>
      <h3 className="text-xl font-bold mb-4">How Do We Use Cookies?</h3>
      <p className="mb-4">The cookies that we use are as follows:</p>
      <div className="pb-2 italic">- Strictly necessary cookies</div>
      <p className="mb-4">
        These cookies are essential to enable you to move around our Browser and
        use its features, such as accessing secure areas of the Browser.
      </p>
      <div className="pb-2 italic">- Functionality cookies</div>
      <p className="mb-4">
        These are cookies that allow our Browser to remember choices you make
        (such as your username in Account, or the region you are in) and to
        provide enhanced, more personal features. These cookies can remember
        changes you have made to text size, fonts, and other parts of web pages
        you can customize. They may also be used to provide services you have
        asked for.
      </p>
      <div className="pb-2 italic">- Analytics cookies</div>
      <p className="mb-4">
        These cookies collect information about how you use our Browsers, for
        example, which pages you go to most often and if you get an error
        message. These cookies are used to improve how our Browser works.
      </p>
      <h3 className="text-xl font-bold mb-4">Managing and Disabling Cookies</h3>
      <p className="mb-4">
        You can change your cookie settings at any time. Our Browser allows some
        control of most cookies through the browser settings. You can manage
        cookie settings using the following link - /insert a link to the cookies
        settings/ at any time.
      </p>
      <p className="mb-4">
        Please keep in mind that simply disabling all of our cookies in our
        Browser settings may lead to certain sections or features of our Browser
        not working.
      </p>
      <h3 className="text-xl font-bold mb-4">
        What are the consequences of disabling cookies?
      </h3>
      <p className="mb-4">
        Сookies that are essential to the function of the Browser will still
        apply to you;
      </p>
      <p className="mb-4">
        You will still see advertising, but it may not be targeted and may not
        be relevant to you; and
      </p>
      <p className="mb-4">
        We will still share your personal data with our service providers to
        provide the services on our Browser.
      </p>
      <p className="mb-4">
        Please note if you are a user your opt-out choice will only be effective
        for our Browser. If you clear your cookies on our Browser, you will need
        to opt-out again.
      </p>
      <p className="mb-4">
        To find more information about cookies please visit:{' '}
        <a href="https://allaboutcookies.org" target="_blank">
          https://allaboutcookies.org
        </a>
        .
      </p>
      <h3 className="text-xl font-bold mb-4">How to Contact Us?</h3>
      <p className="mb-4">
        If you have any questions about this Cookie Policy, please contact us
        via: <a href="mailto:info@chcbrowser.com">info@chcbrowser.com</a>.
      </p>
    </div>
  )
}
