import { Link } from 'react-router-dom'
import { Button } from '../Button'
import { useState } from 'react'

export const Header = () => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)

  return (
    <header className="flex container mx-auto py-5 px-10 md:pt-8 items-center text-white">
      <div className="relative flex-none z-20">
        <Link to={'/'}>
          <img src="/logo.png" alt="" />
        </Link>
      </div>
      <div className="ml-auto">
        <ul className="hidden md:flex items-center gap-8">
          <li>
            <Link to={'/'}>Home</Link>
          </li>
          <li>
            <Link to={'/privacy'}>Benefits</Link>
          </li>
          <li>
            <Button text="Download CHC" iconAlign="right">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M3 11.4118V12.7059C3 13.0491 3.14487 13.3783 3.40273 13.621C3.66059 13.8637 4.01033 14 4.375 14H12.625C12.9897 14 13.3394 13.8637 13.5973 13.621C13.8551 13.3783 14 13.0491 14 12.7059V11.4118M5.0625 7.52941L8.5 10.7647M8.5 10.7647L11.9375 7.52941M8.5 10.7647V3"
                  stroke="white"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
          </li>
        </ul>
        <div
          className="relative flex md:hidden p-4 rounded bg-white bg-opacity-30 z-20 cursor-pointer"
          onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
        >
          {mobileMenuVisible ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M19 19L10 10M10 10L1 1M10 10L19 1M10 10L1 19"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="22"
              height="18"
              viewBox="0 0 22 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1H21"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M1 9H21"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M1 17H21"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          )}
        </div>
        {mobileMenuVisible && (
          <div
            className="flex flex-col justify-center md:hidden fixed top-0 left-0 right-0 bottom-0 z-[19]"
            style={{
              background:
                'linear-gradient( 282deg, #e14a41 -4.11%, #e38035 50.93%, #e4bb28 99.24% )',
            }}
          >
            <ul className="flex flex-col items-center gap-12">
              <li>
                <Link to={'/'}>Home</Link>
              </li>
              <li>
                <Link to={'/privacy'}>Benefits</Link>
              </li>
              <li>
                <Button text="Download CHC" iconAlign="right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M3 11.4118V12.7059C3 13.0491 3.14487 13.3783 3.40273 13.621C3.66059 13.8637 4.01033 14 4.375 14H12.625C12.9897 14 13.3394 13.8637 13.5973 13.621C13.8551 13.3783 14 13.0491 14 12.7059V11.4118M5.0625 7.52941L8.5 10.7647M8.5 10.7647L11.9375 7.52941M8.5 10.7647V3"
                      stroke="white"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
              </li>
            </ul>
            <div
              className="absolute top-0 bottom-0 left-0 w-1/2 bg-no-repeat bg-left-bottom"
              style={{
                backgroundImage: `url('/img/left_side_bg.png')`,
                backgroundSize: '80%',
              }}
            />
            <div
              className="absolute top-0 bottom-0 right-0 w-1/2 bg-no-repeat bg-right-top"
              style={{
                backgroundImage: `url('/img/right_side_bg.png')`,
                backgroundSize: '80%',
              }}
            />
          </div>
        )}
      </div>
    </header>
  )
}
