import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <footer className="relative py-8 px-10 bg-red rounded-tl-3xl rounded-tr-3xl text-white z-10">
      <div className="container mx-auto grid md:grid-cols-3 gap-4">
        <div>
          <div className="pb-2 text-white text-base tracking-wider leading-7 font-medium">
            Privacy Policy
          </div>
          <ul>
            <li className="text-yellow">
              <Link to={'/privacy'}>Privacy notice</Link>
            </li>
            <li className="text-yellow">
              <Link to={'/cookies-policy'}>Cookies policy</Link>
            </li>
            <li className="text-yellow">
              <Link to={'/license-agreement'}>End-user license agreement</Link>
            </li>
            <li className="text-yellow">
              <Link to={'/terms-of-service'}>Terms of service</Link>
            </li>
          </ul>
        </div>
        <div>
          <div className="pb-2 text-white text-base tracking-wider leading-7 font-medium">
            Contacts
          </div>
          <ul>
            <li className="text-yellow">Support</li>
            <li className="text-yellow">
              <a href="mailto:info@chcbrowser.com">info@chcbrowser.com</a>
            </li>
          </ul>
        </div>
        <div>
          <div className="pb-2 text-white text-base tracking-wider leading-7 font-medium">
            Branch
          </div>
          <div>
            <p className="text-yellow">AP Service Limited</p>
            <p className="text-yellow">
              Georgia, Tbilisi, Vake District, Ilia Chavchavadze Avenue, N 40
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
