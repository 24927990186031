import { Link } from 'react-router-dom'

export const NoMatch = () => {
  return (
    <>
      <h2>Страницы не существует!</h2>
      <p>
        <Link to="/">Вернуться на главную</Link>
      </p>
    </>
  )
}
