import { Outlet, useLocation } from 'react-router-dom'
import cn from 'classnames'

import { Header } from '../Header'
import { Footer } from '../Footer'

import styles from './Layout.module.css'
import { Hero } from '../Hero'

export const Layout = () => {
  const location = useLocation()
  const isIndexPage =
    location.pathname === '/' ||
    location.pathname === '/index' ||
    location.pathname === '/home'

  return (
    <>
      <div className={styles.Layout}>
        <Header />

        {isIndexPage && <Hero />}

        <main
          className={cn('bg-white min-h-[70vh] py-10', {
            ['bg-cover bg-no-repeat']: isIndexPage,
          })}
          {...(isIndexPage && {
            style: { backgroundImage: `url('/img/bg.jpg')` },
          })}
        >
          <div className="flex flex-col px-10 container mx-auto">
            {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
            <Outlet />
          </div>
        </main>
      </div>
      <Footer />
    </>
  )
}
