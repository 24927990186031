export const LicenseAgreement = () => {
  return (
    <div className="w-full lg:w-3/4 mx-auto">
      <h2 className="text-2xl font-bold mb-4">End-User License Agreement</h2>
      <div className="mb-4 italic">Last Updated: 10 October 2023</div>
      <p className="mb-4">
        We, the team at AP Service LLC, located at 40 Ilia Chavchavadze Avenue,
        Vake District, Tbilisi, Georgia, are delighted to have you on board. Our
        registered number is 405445111. We're excited to provide you with our
        Services through our user-friendly software. This document is here to
        lay out all the rights and restrictions which apply to the use of the
        software. Feel free to explore and enjoy.
      </p>
      <p className="mb-4">
        The applicable Software, namely this Browser, is made available to you
        under the terms of this Anonymous CHC Browser End-User License Agreement
        (hereinafter - the Agreement). The Agreement is concluded between you,
        the End User, on the one hand and AP Service LLC , (hereinafter - the
        Licensor), on the other
      </p>
      <h3 className="text-xl font-bold mb-4">Acceptance</h3>
      <p className="mb-4">
        By clicking the "Accept" button, or by installing or using this
        Software, you are consenting to be bound by this Agreement. In case you
        do not agree to the terms of this Agreement, do not click the "Accept"
        button, and do not install or use any part of this Software.
      </p>
      <h3 className="text-xl font-bold mb-4">License</h3>
      <p className="mb-4">
        You are granted a non-exclusive and non-transferable, limited License
        (hereinafter - the License) to use and install the Software solely for
        personal, non-commercial purposes. This Agreement constitutes a license
        for use only and is not in any way a transfer of ownership rights to the
        Software. Any violation of any of the foregoing provisions shall be
        deemed a material breach of the Agreement.
      </p>
      <h3 className="text-xl font-bold mb-4">Intellectual Property</h3>
      <p className="mb-4">
        The browser software and any accompanying documentation, graphics, and
        related materials are the intellectual property of the Licensor.. The
        Software is protected by copyright laws and international treaties. All
        rights not expressly granted herein are reserved by the Licensor..
      </p>
      <p className="mb-4">
        The names, logos, and other marks identifying the Licensor and its
        products and services are proprietary trademarks and service marks. You
        are prohibited from using, reproducing, or distributing any of these
        marks without the prior written consent of the Licensor.
      </p>
      <p className="mb-4">
        You may not modify, reverse-engineer, or decompile or create derivative
        works based on this Software in any matter through current or future
        available technologies.
      </p>
      <p className="mb-4">
        Any content you create, upload, or transmit through the Software remains
        your intellectual property. However, by using the Software, you grant
        the Licensor a non-exclusive, worldwide, royalty-free license to use,
        reproduce, modify, adapt, publish, translate, distribute, and display
        such content for the purpose of providing, promoting, and improving the
        Software.
      </p>
      <p className="mb-4">
        The Software may include or provide access to third-party content,
        websites, services, or applications. These are subject to their
        respective intellectual property rights and terms of use. The Licensor
        does not endorse or assume responsibility for third-party content.
      </p>
      <h3 className="text-xl font-bold mb-4">Limitation of Liability</h3>
      <p className="mb-4">
        The product is provided on “as is” and “as available basis” with any
        associated faults. In no matter the Licensor shall be liable for any
        direct, indirect, incidental, special, exemplary, or consequential
        damages arising out of or in any way connected with the use or
        performance of the Software.
      </p>
      <h3 className="text-xl font-bold mb-4">Term of the Agreement</h3>
      <p className="mb-4">
        This Agreement shall enter into force upon its conclusion. The Agreement
        shall be deemed concluded from the moment of your acceptance of its
        provisions. The Agreement shall remain in force until terminated.
      </p>
      <h3 className="text-xl font-bold mb-4">Termination of the Agreement</h3>
      <p className="mb-4">
        In the event of your breach of the terms of this Agreement, the Licensor
        reserves the right to terminate the Agreement immediately and
        unilaterally. Upon termination of the agreement, you must immediately
        cease using the Software and destroy all copies in your possession.
      </p>
      <h3 className="text-xl font-bold mb-4">Miscellaneous</h3>
      <p className="mb-4">
        This Agreement together with the Terms of service, Privacy Notice, and
        Cookie Policy constitutes the agreement between you and the Licensor
        concerning the subject matter hereof, and it may only be modified by a
        written amendment signed by an authorized executive of the Licensor.
      </p>
      <p className="mb-4">
        This Agreement shall be governed by the laws of Georgia.
      </p>
      <p className="mb-4">
        Except as required by law, the controlling language of this Agreement is
        English.
      </p>
    </div>
  )
}
