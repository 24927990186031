import { Routes, Route } from 'react-router-dom'
import { Layout } from './components/Layout'
import { Home } from './routes/Home'
import { NoMatch } from './routes/NoMatch'
import { Privacy } from './routes/Privacy'
import { CookiesPolicy } from './routes/CookiesPolicy'
import { LicenseAgreement } from './routes/LicenseAgreement'
import { TermsOfService } from './routes/TermsOfService'

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="cookies-policy" element={<CookiesPolicy />} />
        <Route path="license-agreement" element={<LicenseAgreement />} />
        <Route path="terms-of-service" element={<TermsOfService />} />

        {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  )
}

export default App
