import { Button } from '../Button'

export const Hero = () => {
  return (
    <div className="relative text-white">
      <div className="relative flex container mx-auto justify-center flex-col items-center px-10 z-10">
        <h1 className="text-4xl md:text-6xl leading-tight md:leading-relaxed text-center pb-1">
          Anonymous CHC Browser
        </h1>
        <p className="text-base leading-5 text-center">
          Your privacy is our top priority here!
        </p>
        <div className="flex flex-col md:flex-row py-6 gap-5">
          <Button title="Download on the" text="App Store">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                d="M32.4542 28.9378C32.0045 30.0245 31.4488 31.0608 30.7958 32.0306C29.9236 33.3219 29.2073 34.2142 28.6583 34.7109C27.8055 35.5224 26.8894 35.9399 25.91 35.9635C25.2083 35.9635 24.3604 35.7564 23.3728 35.3355C22.382 34.9163 21.4724 34.7109 20.6392 34.7109C19.7669 34.7109 18.8313 34.9163 17.8291 35.3355C16.8286 35.7564 16.0197 35.977 15.4008 35.9972C14.4636 36.0393 13.5264 35.6116 12.5924 34.7109C11.9963 34.1721 11.2508 33.2461 10.3558 31.9363C9.39745 30.5406 8.60967 28.9159 7.99244 27.0689C7.33136 25.0705 7 23.1377 7 21.2655C7 19.1223 7.44668 17.272 8.34166 15.723C9.01858 14.5066 9.98633 13.4913 11.1517 12.775C12.3008 12.0606 13.6126 11.6759 14.9525 11.6605C15.6997 11.6605 16.6791 11.8995 17.8925 12.371C19.1058 12.8424 19.8855 13.0814 20.225 13.0814C20.4816 13.0814 21.3441 12.8003 22.8125 12.243C24.198 11.7261 25.3675 11.5123 26.3258 11.5965C28.9247 11.8137 30.8754 12.8744 32.1732 14.7869C29.8505 16.2466 28.7021 18.2889 28.7249 20.9103C28.7444 22.9525 29.4607 24.6513 30.8641 25.9998C31.4845 26.6153 32.2084 27.1077 33 27.4528C32.8278 27.9697 32.6459 28.463 32.4542 28.9378ZM26.498 4.64146C26.498 6.2409 25.9327 7.73595 24.8103 9.1182C23.4524 10.7614 21.8119 11.7127 20.0333 11.5628C20.01 11.3617 19.9986 11.1593 19.9992 10.9567C19.9992 9.41957 20.6424 7.77636 21.7908 6.43115C22.3625 5.75096 23.0902 5.18358 23.9722 4.73237C24.8526 4.2879 25.6842 4.04209 26.4671 4C26.4882 4.2155 26.498 4.42932 26.498 4.64146Z"
                fill="#FEFEFE"
              />
            </svg>
          </Button>
          <Button title="Download on the" text="Google Play">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                d="M20.8159 19.3044L26.039 14.0434L9.1686 4.49289C8.05049 3.88442 7.00305 3.79902 6.08454 4.46442L20.8159 19.3044ZM26.9293 25.4638L32.3591 22.3877C33.4189 21.7899 34 20.943 34 20.0036C34 19.066 33.4189 18.2173 32.3608 17.6195L27.4451 14.8387L21.9111 20.411L26.9293 25.4638ZM5.17664 5.75787C5.06359 6.10836 5 6.49978 5 6.92856V33.0911C5 33.7689 5.14837 34.3525 5.41686 34.8169L19.719 20.4092L5.17664 5.75787ZM20.8159 21.5123L6.59325 35.8399C6.86527 35.9448 7.15848 36 7.4676 36C8.0187 36 8.59276 35.8363 9.17743 35.509L25.525 26.2627L20.8159 21.5123Z"
                fill="#FEFEFE"
              />
            </svg>
          </Button>
        </div>
      </div>
      <div
        className="absolute top-0 bottom-0 left-0 w-1/2 bg-no-repeat"
        style={{ backgroundImage: `url('/img/left_side_bg.png')` }}
      />
      <div
        className="absolute top-0 bottom-0 right-0 w-1/2 bg-no-repeat bg-right"
        style={{ backgroundImage: `url('/img/right_side_bg.png')` }}
      />
      <div
        className="hidden md:flex relative h-[40vw] bg-no-repeat bg-center bg-cover after:content-[''] after:absolute after:bottom-0 after:left-0 after:right-0 after:bg-white after:h-10 md:after:h-20 lg:after:h-24"
        style={{ backgroundImage: `url('/img/browser.png')` }}
      />
      <div
        className="md:hidden relative h-[50vw] bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: `url('/img/mobile.png')` }}
      />
    </div>
  )
}
