export const TermsOfService = () => {
  return (
    <div className="w-full lg:w-3/4 mx-auto">
      <h2 className="text-2xl font-bold mb-4">Terms of Service</h2>
      <div className="mb-4 italic">Last updated: 10 October 2023</div>
      <p className="mb-4">
        We, the team at AP Service LLC, located at 40 Ilia Chavchavadze Avenue,
        Vake District, Tbilisi, Georgia, are delighted to have you on board. Our
        registered number is 405445111. We're excited to provide you with our
        Services through our user-friendly software. This Terms of Service is
        here to lay out all the terms and conditions that will guide your use of
        our software. Feel free to explore and enjoy.
      </p>

      <h3 className="text-xl font-bold mb-4">1. An agreement between us.</h3>

      <p className="mb-4">
        a. These Terms, together with the Privacy Notice, Cookie Policy and End
        User Licence Agreement, constitute the agreement between you, as the end
        user and AP Services and its affiliates (the expressions "Browser",
        "we", "us", "we", "our", "Software" or "Software" may be used
        hereinafter). By using the Services you agree to be legally bound by
        these Terms. Please do not use our Browser if you do not agree to these
        Terms in any way.
      </p>

      <p className="mb-4">
        b. Our Services are any services that you may receive using our Browser,
        such as searching, displaying third party web pages and the like.
      </p>

      <p className="mb-4">
        c. Our browser exists in several formats. These include a mobile version
        for multiple operating systems and a personal computer version. In these
        Terms, any reference to the Browser implies the Browser in any format.
      </p>

      <h3 className="text-xl font-bold mb-4">2. Changes to our Terms.</h3>

      <p className="mb-4">
        a. We may make changes to these Terms at any time. The current version
        of these Terms is available at _____________ You can find the Privacy
        Notice here:{' '}
        <a href="https://chcbrowser.com/privacy" target="_blank">
          https://chcbrowser.com/privacy
        </a>
        . It is your responsibility to keep up to date with any changes. If you
        continue to use our Browser after the latest changes have been
        published, you become legally bound by the latest version of the Terms
        and Privacy Notice. You may not assign or transfer your rights under
        these Terms without our prior written consent and any purported
        assignment or transfer in breach of this section will be void.
      </p>

      <h3 className="text-xl font-bold mb-4">
        3. Rules of Behaviour to which you are committed
      </h3>

      <p className="mb-4">
        a. You agree that you will not use the Services for any purpose that
        violates laws or is prohibited by these Terms. You also agree to comply
        with all applicable local, national, and international laws and
        regulations. Your responsibility extends to all your acts and omissions
        while using the Services, including the content of any data you send
        through the Services and all content you upload or post using the
        Services, if applicable.
      </p>

      <p className="mb-4">
        b. You agree to be respectful of the rights of others. By using the
        Services, you agree not to post, transmit or otherwise make available
        files, images, code, materials or other information and any other
        content that violates the rights of any third party, including their
        intellectual property rights.
      </p>

      <p className="mb-4">
        c. You also agree not to upload, transmit or otherwise make available
        any content that is obscene, vulgar, sexually orientated, or hate
        speech.
      </p>

      <p className="mb-4">
        d. By this provision, we expressly prohibit the publication of any
        material containing sexual abuse of anyone and will terminate the access
        of any user who publishes or distributes material containing sexual
        abuse of anyone. In addition, we will report such users to the
        appropriate authorities.
      </p>

      <p className="mb-4">
        e. You are solely responsible for the security and any activity in your
        accounts on any third-party sites you may access using the Browser.
      </p>

      <p className="mb-4">
        f. You acknowledge that information of any kind provided to you through
        our Browser may be protected by copyright, trademark, patent or other
        proprietary rights and laws. You agree not to violate these laws or
        these rights in any way.
      </p>

      <p className="mb-4">
        g. If you use our Browser to download any Content, you confirm that you
        have all necessary rights and authorisation to do so, including
        obtaining the necessary consent to download and share any personal
        information about third parties. You also agree not to attempt to
        introduce viruses or other malware.
      </p>

      <h3 className="text-xl font-bold mb-4">4. License and Copyright.</h3>

      <p className="mb-4">
        a. Our browser is built on the Chromium core. We use the licence
        provided by Chromium, which provides the free use of the software. You
        can read more about this licence here.
      </p>

      <p className="mb-4">
        In accordance with the license provisions, we are obliged to include the
        following provisions below:
      </p>

      <p className="mb-4">Copyright 2015 The Chromium Authors.</p>

      <p className="mb-4">
        THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS "AS
        IS" AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED
        TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
        PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT OWNER
        OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
        EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO,
        PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR
        PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF
        LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING
        NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS
        SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE
      </p>

      <p className="mb-4">
        b. However, this Browser is our original product, the copyright of which
        we own in full. Any use of our product without our authorisation is an
        infringement of our copyright and will be prosecuted.
      </p>

      <h3 className="text-xl font-bold mb-4">5. Licence we grant to you.</h3>

      <p className="mb-4">
        a. Our company by these Terms grants you a personal, worldwide,
        royalty-free, non-assignable and non-exclusive licence to use our
        Browser provided to you by our company as part of the Services. This
        licence is for the sole purpose of enabling you to use and benefit from
        our Services in the manner set out in these Terms.
      </p>

      <p className="mb-4">
        b. Subject to the preceding paragraph, you may not (and may not
        authorise anyone else to) copy, modify, create derivative works of,
        reverse engineer, decompile or otherwise attempt to extract the source
        code of the Browser or any part of the Browser, except as expressly
        permitted or required by law, or unless you have received written notice
        from us that you may do so.
      </p>

      <p className="mb-4">
        c. Subject to the preceding section, unless we have specifically
        authorised you in writing to do so, you may not assign (or sub-licence)
        your rights to use the Browser, grant a security interest in your rights
        to use the Browser or otherwise transfer any part of your rights to use
        our Software.
      </p>

      <h3 className="text-xl font-bold mb-4">
        6. Intellectual Property Dispute Resolution Policy.
      </h3>

      <p className="mb-4">
        If you believe that any content accessed through our Browser infringes
        your rights, you have the option to send us a notice containing the
        following information:
      </p>

      <ul className="mb-4">
        <li>a. Describe the specific right you believe has been violated;</li>
        <li>
          b. Provide the identifier of the content that you believe infringes
          your rights (including the URL);
        </li>
        <li>c. Provide your name, address, phone number and email address;</li>
        <li>
          d. Give a statement that you have a good faith belief that using the
          content in the manner you have indicated is not in accordance with the
          authorisations of the copyright holder, its agent or applicable law;
        </li>
        <li>
          e. Confirm that the information provided in the notice is correct and
          you represent under penalty of perjury that you have authority to act
          on behalf of the owner of the exclusive rights allegedly infringed;
        </li>
        <li>
          f. Sign the notice with your physical or electronic signature, or with
          the signature of a person acting on your behalf and authorised by the
          owner of the exclusive rights alleged to have been infringed.
        </li>
        <li>
          g. Use the following address to send complaints:{' '}
          <a href="mailto:info@chcbrowser.com">info@chcbrowser.com</a>.
        </li>
      </ul>

      <h3 className="text-xl font-bold mb-4">
        7. What can you access through our Browser?
      </h3>

      <p className="mb-4">Our browser offers the following functionality:</p>

      <p className="mb-4">
        a. Extension Catalogue. At the moment we do not offer an extension shop.
        But in the future, as we develop, we may add such functionality. Third
        party extensions may be available to you through such a shop, which will
        be pre-screened for compliance with these Terms and any other applicable
        policies. We are not and will not be liable for any damages, tangible or
        intangible, that you may incur by using third-party extensions.
      </p>

      <p className="mb-4">
        b. Plugins. A plugin is a package of code that "plugs in" to our
        Browser, allowing us to embed certain features, gifs, videos, etc. into
        websites. For example, this could be Adobe Flash Player, PDF Viewer and
        Java and other plugins. We provide the option of installing third-party
        plug-ins, which are pre-tested before publication.
      </p>

      <p className="mb-4">
        c. Fast Downloading. Our software includes a compression feature that
        allows users to download content, such as web pages or videos, faster.
        This feature requests content through special compression servers. Due
        to the increased download speeds, the quality of your web browsing
        experience may change. Some web pages may not be accessible through
        proxy servers.
      </p>

      <p className="mb-4">
        d. Modules. Modules are special software that can serve as an "add-on"
        to our Browser, to provide convenience and security to users. These
        modules will be invisible to you as a User, but we notify you of their
        use and do not make a 100 percent guarantee that they will function
        uninterrupted or uninterrupted.
      </p>

      <p className="mb-4">
        e. Screenshot. Our Browser contains features that allow you to take
        screenshots of content viewed through the Browser. This feature is for
        your personal, non-commercial use only. You agree not to use this
        feature in any way that violates applicable law or the rights of third
        parties, including copyrights.
      </p>
      <p className="mb-4">
        f. Other functionality. We reserve the right to make changes to the
        functionality of our Browser at any time without prior notice.
      </p>

      <h3 className="text-xl font-bold mb-4">8. Your Access to our Services</h3>

      <p className="mb-4">
        a. Your ability to access the Services is subject to change at any time.
        We may at any time make changes to the provision of the Services, either
        in part or in whole, or restrict your access to the Services with or
        without prior notice. By using the Services, you agree that we accept no
        liability to you or any third party for any modification, temporary
        suspension or permanent discontinuance of the Services. Importantly, we
        reserve the right to assign our rights and obligations under these Terms
        to any third party. This allows us to effectively manage the provision
        of the Services and to maintain their relevance and quality.
      </p>

      <h3 className="text-xl font-bold mb-4">
        9. Third-Party Provision of Services.
      </h3>

      <p className="mb-4">
        a. As of the date these Terms are updated, we do not engage third
        parties to provide services through our Browser. However, we may begin
        using third-party services in the future. We reserve the right to
        integrate modules, plug-ins and extensions provided by third parties
        without prior notice.
      </p>
      <h3 className="text-xl font-bold mb-4">
        10. Your access to third-party websites.
      </h3>

      <p className="mb-4">
        a. Through our Browser, you have access to third-party websites. The
        Browser may provide links to other websites and content in the form of
        articles, photographs, text, graphics, designs, music, sound, video,
        information, applications, software and other materials that are owned
        by or provided by third parties. It is important to note that we cannot
        and do not control third-party content that you access through our
        Services. Our exposure does not extend or extend to third-party content
        or external websites.
      </p>

      <p className="mb-4">
        b. Please remember that the inclusion of links to third-party sites or
        permission to use their content does not imply that we endorse or
        approve of those sites. By using our Services, you agree that content
        provided by independent third parties reflects their own views and
        opinions. We are not responsible for such content and do not represent
        that we share the views expressed on third-party websites. It is
        important to emphasise that your interactions with such third-party
        resources are at your sole risk and discretion.
      </p>

      <h3 className="text-xl font-bold mb-4">11. Disclaimer of Warranties.</h3>

      <p className="mb-4">
        a. The Services are provided without any form of warranty or guarantee.
        We cannot guarantee that your use of the Services will not cause
        problems or any irregularities. While we endeavour to provide the
        highest quality software and Services, we cannot and do not warrant that
        they will perform flawlessly at all times or in all aspects.
      </p>

      <p className="mb-4">
        b. We provide our Services on an "as is" basis, i.e. without warranty of
        any kind. By this provision, we and/or our respective suppliers disclaim
        all warranties and conditions with respect to the Services, including
        all implied warranties and conditions of merchantability, fitness for a
        particular purpose, title and non-infringement.
      </p>

      <p className="mb-4">
        c. We do not represent or warrant that the Services will be
        uninterrupted or error-free, that defects will be corrected, or that the
        Services or the server that makes them available are free of viruses or
        other harmful components.
      </p>

      <p className="mb-4">
        d. Under the laws of certain jurisdictions, certain Services and
        websites may not be available in certain countries.
      </p>

      <h3 className="text-xl font-bold mb-4">12. Advertising</h3>

      <p className="mb-4">
        а. We provide our Services free of charge. However, to cover our costs,
        such as developing software, maintaining the quality of the Services and
        possibly paying third party services, we may display advertisements to
        you. We reserve the right not to notify you that it is an advertisement.
      </p>

      <h3 className="text-xl font-bold mb-4">
        13. Limitation of our liability
      </h3>

      <p className="mb-4">
        We will not be liable for any damage you may suffer as a result of using
        our Services.
      </p>

      <p className="mb-4">
        a. In this provision, you agree that we are not responsible for
        unauthorized access to or alteration of your data, any materials or data
        sent or received or not sent or received, or any transactions conducted
        through the Services.
      </p>

      <p className="mb-4">
        b. You agree that we are not responsible for threatening, libelous,
        defamatory, obscene, abusive or unlawful content or behaviour of any
        other party, or for any infringement of the rights of others, including
        intellectual property rights. You agree that we are not responsible for
        any content sent using the Services and/or included by third parties.
      </p>

      <p className="mb-4">
        c. In no event shall we be liable for any direct, indirect, punitive,
        incidental, special, consequential damages or any other damages
        whatsoever, including, without limitation, damages for loss of use, data
        or profits, arising out of or in any way related to the use or
        performance of the Services, the delay or inability to use the Services,
        the provision or non-provision of any Services, or for any information,
        software, products, services and related graphics obtained through the
        Services, or for any information, software, products, services and
        related graphics obtained through the Services Because some
        states/jurisdictions do not allow the exclusion or limitation of
        liability for consequential or incidental damages, the above limitation
        may not apply in every case. If you are dissatisfied with any part of
        the Services or any of these terms and conditions, your sole and
        exclusive remedy is to discontinue using the Services and related
        websites.
      </p>

      <p className="mb-4">
        d. You agree to indemnify and hold harmless our company, its parents,
        subsidiaries, affiliates, officers and employees from any claim, demand
        or damage, including reasonable attorneys' fees, brought by a third
        party due to your use of or conduct on the Services.
      </p>

      <h3 className="text-xl font-bold mb-4">
        14. Jurisdiction and Applicable Law.
      </h3>

      <p className="mb-4">
        a. Our Terms are drafted to be governed by the laws of Georgia . The
        laws of Georgia shall govern the operation of these Terms, excluding any
        conflict of laws principles that might require the application of the
        laws of another country. It is important to note that the United Nations
        Convention on Contracts for the International Sale of Goods does not
        apply to these Terms.
      </p>

      <p className="mb-4">
        b. Any legal action or proceedings relating to or arising out of these
        Terms must first be negotiated between the parties. In the event that we
        are unable to resolve our dispute with you, we will be forced to take
        legal action. The jurisdiction in which we take legal action shall be at
        our discretion. You irrevocably agree to submit to the jurisdiction and
        venue of all such actions or proceedings in that court. In the event
        that any provision of our Terms is held by a court of competent
        jurisdiction to be invalid, illegal or unenforceable, this will not
        affect the remaining provisions of these Terms, which will remain in
        full force and effect and will not be affected by such decision.
      </p>

      <h3 className="text-xl font-bold mb-4">
        15. Processing your personal data
      </h3>

      <p className="mb-4">
        a. Please read the Privacy Notice and Cookie Policy carefully. By
        understanding these policies you will be able to better understand how
        you can manage your data and preferences regarding cookies and your
        personal data.
      </p>
    </div>
  )
}
